import * as React from "react";
const SvgVideoPlayIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={19}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M9.502 18.996a9.44 9.44 0 0 1-1.875-.185 9.34 9.34 0 0 1-1.768-.528 9.585 9.585 0 0 1-1.63-.869 10.112 10.112 0 0 1-1.446-1.201 9.016 9.016 0 0 1-1.191-1.445 10.12 10.12 0 0 1-.88-1.631 9.57 9.57 0 0 1-.536-1.768A9.894 9.894 0 0 1 0 9.504c0-.638.059-1.26.176-1.865A9.57 9.57 0 0 1 .713 5.87c.24-.573.534-1.116.879-1.63a9.624 9.624 0 0 1 2.637-2.646 9.58 9.58 0 0 1 1.63-.87A8.924 8.924 0 0 1 7.627.188 9.44 9.44 0 0 1 9.502.002c.631 0 1.253.062 1.865.185a8.668 8.668 0 0 1 1.768.538 9.113 9.113 0 0 1 1.63.869c.521.345 1.006.742 1.456 1.191.449.45.846.934 1.191 1.455.345.515.635 1.058.87 1.631a9.569 9.569 0 0 1 .722 3.633 9.568 9.568 0 0 1-1.592 5.264 9.014 9.014 0 0 1-1.191 1.445c-.45.45-.934.85-1.455 1.201a9.118 9.118 0 0 1-1.631.87 9.065 9.065 0 0 1-1.768.527 9.39 9.39 0 0 1-1.865.185Zm0-17.998a8.226 8.226 0 0 0-3.31.674 8.588 8.588 0 0 0-2.706 1.826A8.659 8.659 0 0 0 1.66 6.203a8.26 8.26 0 0 0-.664 3.3 8.33 8.33 0 0 0 .664 3.312 8.692 8.692 0 0 0 1.826 2.695 8.811 8.811 0 0 0 2.705 1.826A8.33 8.33 0 0 0 9.502 18a8.26 8.26 0 0 0 3.3-.664 8.658 8.658 0 0 0 2.706-1.826 8.554 8.554 0 0 0 1.816-2.695 8.226 8.226 0 0 0 .674-3.311 8.157 8.157 0 0 0-.674-3.3 8.523 8.523 0 0 0-4.521-4.532A8.157 8.157 0 0 0 9.502.998ZM6.504 15.002a.775.775 0 0 1-.127-.01.494.494 0 0 1-.117-.058.505.505 0 0 1-.196-.176.495.495 0 0 1-.068-.254v-10c0-.091.023-.176.068-.254a.59.59 0 0 1 .196-.186.432.432 0 0 1 .254-.058c.09 0 .175.023.254.068l7.998 5a.458.458 0 0 1 .166.186.478.478 0 0 1 0 .488.476.476 0 0 1-.166.176l-7.998 5a.496.496 0 0 1-.264.078Zm.498-9.6v8.194l6.553-4.092-6.553-4.102Z"
    />
  </svg>
);
export default SvgVideoPlayIcon;
