import * as React from "react";
const SvgMinimizeButton = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={41}
    height={41}
    fill="none"
    {...props}
  >
    <g filter="url(#minimize_button_svg__a)">
      <path
        fill="#fff"
        d="M29.604 18H26a2.61 2.61 0 0 1-1.916-.791 2.61 2.61 0 0 1-.791-1.916v-3.586c0-.258.088-.475.264-.65a.884.884 0 0 1 .65-.264c.246 0 .457.088.633.264a.884.884 0 0 1 .264.65v3.586c0 .258.087.475.263.65a.863.863 0 0 0 .633.264h3.604c.246 0 .457.088.632.264a.863.863 0 0 1 .264.633.863.863 0 0 1-.264.632.863.863 0 0 1-.633.264Zm-14.397 0h-3.604a.863.863 0 0 1-.632-.264.863.863 0 0 1-.264-.633c0-.246.088-.457.264-.632a.863.863 0 0 1 .633-.264h3.603a.863.863 0 0 0 .633-.264.884.884 0 0 0 .264-.65v-3.586c0-.258.087-.475.263-.65a.863.863 0 0 1 .633-.264c.246 0 .457.088.633.264a.884.884 0 0 1 .264.65v3.586a2.61 2.61 0 0 1-.791 1.916 2.59 2.59 0 0 1-1.899.791Zm9 12.604a.884.884 0 0 1-.65-.264.863.863 0 0 1-.264-.633v-3.604c0-.75.264-1.388.791-1.916a2.61 2.61 0 0 1 1.916-.79h3.604c.246 0 .457.087.632.263a.863.863 0 0 1 .264.633.884.884 0 0 1-.264.65.863.863 0 0 1-.633.264H26a.863.863 0 0 0-.633.264.863.863 0 0 0-.264.633v3.603a.863.863 0 0 1-.263.633.863.863 0 0 1-.633.264Zm-7.207 0a.863.863 0 0 1-.633-.264.863.863 0 0 1-.264-.633v-3.604a.863.863 0 0 0-.263-.632.863.863 0 0 0-.633-.264h-3.604a.863.863 0 0 1-.632-.264.884.884 0 0 1-.264-.65c0-.246.088-.457.264-.633a.863.863 0 0 1 .633-.264h3.603a2.59 2.59 0 0 1 1.898.791 2.61 2.61 0 0 1 .791 1.916v3.604a.863.863 0 0 1-.263.633.863.863 0 0 1-.633.264Z"
      />
    </g>
    <defs>
      <filter
        id="minimize_button_svg__a"
        width={39.793}
        height={39.81}
        x={0.707}
        y={0.793}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2439_17235"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_2439_17235"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export default SvgMinimizeButton;
