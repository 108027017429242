import React from 'react';
import PropTypes from 'prop-types';
import { isMobile, isSafari } from 'react-device-detect';
import { useStoreActions } from 'easy-peasy';
import { MaximizeButton, MinimizeButton } from '../../../shared/svg/components';

const FooterCreditsFullScreen = ({ isMaximized, onMaximize, onMinimize }) => {
    const setLegalNoteOpened = useStoreActions(actions => actions.setLegalNoteOpened);
    const onLegalNoteHandler = () => {
        setLegalNoteOpened(true);
    };

    return (
      <div className="footer-credits-full-screen">
        <div className="divider">|</div>
        <button
          type="button"
          onClick={onLegalNoteHandler}
          className="legal-note-button"
        >
          Aviso Legal
        </button>
        <div className="divider">|</div>
        <div className="by">by</div>
        <div className="bizionar-logo"><img src={`${process.env.PUBLIC_URL}/img/by-bizionar.png`} alt="" /></div>
        {(isMobile && isSafari) ? null : (
          <button type="button" onClick={isMaximized ? onMinimize : onMaximize} className="full-screen-button">
            {isMaximized ? <MinimizeButton className="icon" /> : <MaximizeButton className="icon" />}
          </button>
            )}
      </div>
    );
};

FooterCreditsFullScreen.propTypes = {
    isMaximized: PropTypes.bool.isRequired,
    onMaximize: PropTypes.func.isRequired,
    onMinimize: PropTypes.func.isRequired,
};

export default FooterCreditsFullScreen;
