/* eslint-disable */
import {action} from 'easy-peasy';
import {ExperienceType} from "./constants";

export default {
  settings: {},
  experiences: [],
    // State
  /* settings: {
    client: "Culmia",
    promotion: "Sunset Bay Estepona",
    claim: "Disfruta de la experiencia de conocer cómo será tu futuro hogar a través de diferentes opciones virtuales",
    render: {
      url: "https://media.bizionar.digital/culmia/estepona/live/cam_04.jpg"
    },
    video: {
      url: "https://media.bizionar.digital/culmia/estepona/live/video_completo.mp4",
      miniPlayerUrl: "https://media.bizionar.digital/culmia/estepona/live/video_exterior_md.mp4"
    },
    legalNote: "Todo el contenido de esta visita virtual es meramente informativo y no reviste carácter contractual. Si bien la información facilitada se ha preparado de buena fe y se cree exacta, la misma no pretende ser exhaustiva. La promotora se reserva la posibilidad de realizar modificaciones de lo aquí mostrado sin previo aviso. La información del R.D 515/89 de 21 de abril se encuentra en disposición del público en las oficinas de la promotora.<br/><br/>Las viviendas mostradas se tratan de viviendas tipo que pueden variar en función de la tipología. Asimismo, el mobiliario, electrodomésticos, la decoración y los elementos de ambientación, tales como espejos, mamparas, luminarias y otros que aparecen en el contenido, son de carácter meramente ornamental y para fines estrictamente comerciales, no siendo objeto de contratación."
  },
  experiences: [
    {
      id: ExperienceType.TourVirtual,
      type: ExperienceType.TourVirtual,
      url: "https://media.bizionar.digital/culmia/estepona/live/tour/index.html?l=0"
    },
    {
      id: ExperienceType.VistasReales,
      type: ExperienceType.VistasReales,
      url: "https://media.bizionar.digital/culmia/estepona/live/tour-vistas/index.html"
    },
    {
      id: ExperienceType.SolarSimulation,
      type: ExperienceType.SolarSimulation,
      url: "https://solar-simulation-showroom.bizionar.digital/culmia/estepona/live",
      mobileUrl: "https://solar-simulator.bizionar.digital/culmia/estepona/live"
    },
    {
      id: ExperienceType.Configurator,
      type: ExperienceType.Configurator,
      url: "https://culmia-f0.bizionar.digital/estepona"

    }
  ],

   */
  iframe: null,
  isIFrameExperienceOpened: false,
  isVideoOpened: false,
  currentExperience: null,
  currentVideoUrl: null,
  isLegalNoteOpened: false,

  initializePromotion: action((state, data) => {
    state.settings = data.settings;
    state.experiences = data.experiences;
  }),
  // Actions
  openExperience: action((state, experience) => {
    state.currentExperience = experience;
    state.isIFrameExperienceOpened = true;
  }),
  openVideo: action((state, url) => {
    state.currentVideoUrl = url;
    state.isVideoOpened = true;
  }),
  closeExperience: action((state, iframe) => {
    state.currentExperience = null;
    state.currentVideoUrl = null;
    state.isIFrameExperienceOpened = false;
    state.isVideoOpened = false;
    state.isGalleryExperienceOpened = false;
  }),
  setIframe: action((state, iframe) => {
    state.iframe = iframe;
  }),
  setLegalNoteOpened: action((state, isOpened) => {
    state.isLegalNoteOpened = isOpened;
  }),
};
