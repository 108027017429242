import React from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import PropTypes from 'prop-types';
import FooterCreditsFullScreen from './Component/FooterCreditsFullScreen';
import {
    AerialIcon,
    BackFromExperienceArrow,
    ConfiguratorIcon, MaquetaVirtualIcon,
    MultimediaIcon,
    SolarSimulationIcon,
    TourIcon,
    TourVirtualIcon, VideoPlayIcon,
    VistasIcon, VistasReales, VistasRealesCulmia,
} from '../../shared/svg/components';
import { ExperienceType } from '../../model/constants';
import { ExperienceUtils } from '../../utils/ExperienceUtils';


const FooterExperience = ({ isMaximized, onMaximize, onMinimize }) => {
    const currentExperience = useStoreState(state => state.currentExperience);
    const currentVideoUrl = useStoreState(state => state.currentVideoUrl);
    const closeExperience = useStoreActions(actions => actions.closeExperience);

    const closeHandler = () => {
        closeExperience();
    };

    const getExperienceIcon = () => {
        if (currentVideoUrl !== null) {
            return <VideoPlayIcon className="video-icon" />;
        } if (currentExperience === null) return '';

        switch (currentExperience.type) {
            case ExperienceType.TourVirtual:
            case ExperienceType.TourVirtualV0:
            case ExperienceType.TourVirtualV1:
            case ExperienceType.TourVirtualV2:
            case ExperienceType.TourVirtualV3:
            case ExperienceType.TourVirtualPisoPiloto:
            case ExperienceType.TourVirtualPatio:
            case ExperienceType.PisoPiloto:
                return <TourVirtualIcon className="tour-icon" />;
            case ExperienceType.VistasReales:
                return <VistasRealesCulmia className="vistas-icon" />;
            case ExperienceType.SolarSimulation:
                return <SolarSimulationIcon className="solar-simulation-icon" />;
            case ExperienceType.Configurator:
                return <ConfiguratorIcon className="configurator-icon" />;
            case ExperienceType.MaquetaVirtual:
                return <MaquetaVirtualIcon className="maqueta-icon" />;
            default:
                return null;
        }
    };

    return (
      <div className="footer-experience">
        <div className="left-footer">
          <button type="button" className="back-button" onClick={closeHandler}>
            <BackFromExperienceArrow />
          </button>
          <div className="title-experience">
            {getExperienceIcon()}
            <div className="title-first-part">
              {ExperienceUtils.GetFooterExperienceTitleFirstPart(currentExperience)}
            </div>
            <div className="title-second-part">
              {ExperienceUtils.GetFooterExperienceTitleSecondPart(currentExperience)}
            </div>
          </div>
        </div>
        <FooterCreditsFullScreen isMaximized={isMaximized} onMaximize={onMaximize} onMinimize={onMinimize} />
      </div>
    );
};

FooterExperience.propTypes = {
    isMaximized: PropTypes.bool.isRequired,
    onMaximize: PropTypes.func.isRequired,
    onMinimize: PropTypes.func.isRequired,
};

export default FooterExperience;
