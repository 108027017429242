import React, { useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useQuery } from 'react-query';
import { isMobile } from 'react-device-detect';
import { useParams } from 'react-router-dom';
import RenderPlayer from '../Player/RenderPlayer';
import ExperienceButton from './Components/ExperienceButton';
import FooterExperience from '../Layout/FooterExperience';
import IframePlayer from '../Player/IframePlayer';
import VideoMiniPlayer from '../Player/VideoMiniPlayer';
import VideoPlayer from '../Player/VideoPlayer';
import useWindowSize from '../../hooks/useWindowSize';
import { VideoPlayIcon } from '../../shared/svg/components';
import LegalNote from '../LegalNote/LegalNote';
import HeaderExperience from '../Layout/HeaderExperience';

const DigitalShow = () => {
  const { promotionId } = useParams();
  const settings = useStoreState(state => state.settings);
  const experiences = useStoreState(state => state.experiences);
  const isIFrameExperienceOpened = useStoreState(state => state.isIFrameExperienceOpened);
  const isVideoOpened = useStoreState(state => state.isVideoOpened);
  const openVideo = useStoreActions(actions => actions.openVideo);
  const initializePromotion = useStoreActions(actions => actions.initializePromotion);
  const windowsSize = useWindowSize();

  const isPortrait = () => windowsSize.height > windowsSize.width;
  const isMobilePortrait = () => isMobile || isPortrait();

  useEffect(() => {
  }, [isIFrameExperienceOpened, isVideoOpened]);

  const handle = useFullScreenHandle();

  // eslint-disable-next-line max-len
  const url = `https://media.bizionar.digital/culmia/${promotionId}/show/${promotionId}.json?random=${Math.round(Math.random() * 10000000)}`;
  const { isLoading, error } = useQuery(
      ['digital-show'],
      () => fetch(url).then(res => res.json()).then(data => initializePromotion(data)),
  );

  if (isLoading) return 'Loading...';

  if (error) return `An error has occurred: ${error.message}`;

  if (!isMobilePortrait()) {
    return (
      <FullScreen handle={handle}>
        <div className="digital-show">
          <div className="container-main-screen">
            <div className="top-container">
              <div className="container-render-video">
                {settings.video !== undefined
                    ? <VideoMiniPlayer url={settings.video.miniPlayerUrl} />
                    : <RenderPlayer url={settings.render.url} />}
                {/* settings.video !== null ? (
                  <button className="button-open-video" type="button" onClick={() => openVideo(settings.video.url)}>
                    <VideoPlayIcon />
                    <div className="title">Video</div>
                  </button>
                ) : null */}
              </div>
              <div className="container-promotion-info">
                <div className="container-client-promotion">
                  <div className="client">{settings.client}</div>
                  <div className="promotion">{settings.promotion}</div>
                </div>
                <div className="container-divider-claim">
                  <div className="divider">divider</div>
                  {/* eslint-disable-next-line max-len */}
                  <div className="claim">Disfruta de la experiencia de conocer cómo será tu nuevo hogar a través de diferentes opciones virtuales</div>
                </div>
              </div>
            </div>
            <div className="bottom-container">
              {experiences.map(experience => (
                <ExperienceButton
                  experience={experience}
                  isMobilePortrait={isMobilePortrait()}
                />
              ))}
            </div>
          </div>
          {isIFrameExperienceOpened ? (
            <div className="container-experience">
              <IframePlayer />
              <FooterExperience isMaximized={handle.active} onMaximize={handle.enter} onMinimize={handle.exit} />
            </div>
          ) : null}
          {isVideoOpened ? (
            <div className="container-video">
              <VideoPlayer />
              <FooterExperience isMaximized={handle.active} onMaximize={handle.enter} onMinimize={handle.exit} />
            </div>
          ) : null}
        </div>
        <LegalNote />
      </FullScreen>
    );
  }
    return (
      <FullScreen handle={handle}>
        <div
          className={`digital-show-portrait ${(isIFrameExperienceOpened || isVideoOpened) ? 'experience-opened' : ''}`}
        >
          {(!isIFrameExperienceOpened && !isVideoOpened) ? (
            <div
              className="container-main-screen"
            >
              <div className="top-container">
                <div className="container-promotion-info">
                  <div className="container-client-promotion">
                    <div className="client">{settings.client}</div>
                    <div className="promotion">{settings.promotion}</div>
                  </div>
                </div>
                <div className="container-render-video">
                  {settings.video !== undefined
                    ? <VideoMiniPlayer url={settings.video.miniPlayerUrl} />
                    : <RenderPlayer url={settings.render.url} />}
                  {/* settings.video !== undefined ? (
                    <div className="container-button-open-video">
                      <button className="button-open-video" type="button" onClick={() => openVideo(settings.video.url)}>
                        <VideoPlayIcon />
                        <div className="title">Video</div>
                      </button>
                    </div>
                ) : null */}
                </div>
              </div>
              <div className="bottom-container">
                {experiences.map(experience => (
                  <ExperienceButton
                    experience={experience}
                    isMobilePortrait={isMobilePortrait()}
                  />
            ))}
              </div>
            </div>
          ) : null}
          {isIFrameExperienceOpened ? (
            <div className="container-experience">
              <HeaderExperience isMaximized={handle.active} onMaximize={handle.enter} onMinimize={handle.exit} />
              <IframePlayer />
            </div>
          ) : null}
          {isVideoOpened ? (
            <div className="container-video">
              <HeaderExperience isMaximized={handle.active} onMaximize={handle.enter} onMinimize={handle.exit} />
              <VideoPlayer />
            </div>
          ) : null}
        </div>
        <LegalNote />
      </FullScreen>
    );
};

export default DigitalShow;
