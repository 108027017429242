import {ExperienceType} from "../model/constants";

export const ExperienceUtils = {
    GetExperienceTitle: (experience) => {
        if (experience === null) return '';

        switch (experience.type) {
            case ExperienceType.TourVirtual:
                return 'VISITA VIRTUAL';
            case ExperienceType.TourVirtualV0:
                return 'TOUR VIRTUAL';
            case ExperienceType.TourVirtualV1:
                return 'VISITA VIRTUAL 01';
            case ExperienceType.TourVirtualV2:
                return 'VISITA VIRTUAL 02';
            case ExperienceType.TourVirtualV3:
                return 'VISITA VIRTUAL 03';
            case ExperienceType.TourVirtualPisoPiloto:
                return 'VISITA VIRTUAL PISO PILOTO';
            case ExperienceType.TourVirtualPatio:
                return 'VISITA VIRTUAL PATIO';
            case ExperienceType.ZonasComunes:
                return 'VISITA ZONAS COMUNES';
            case ExperienceType.PisoPiloto:
                return 'PISO PILOTO';
            case ExperienceType.VistasReales:
                return 'VISTAS REALES';
            case ExperienceType.SolarSimulation:
                return 'SIMULADOR SOLAR';
            case ExperienceType.Configurator:
                return 'CONFIGURADOR';
            case ExperienceType.MaquetaVirtual:
                return 'MAQUETA VIRTUAL';
            default:
                return '';
        }
    },

    GetFooterExperienceTitleFirstPart: (experience) => {
        if (experience === null) return '';

        switch (experience.type) {
            case ExperienceType.TourVirtual:
                return 'VISITA';
            case ExperienceType.TourVirtualV0:
                return 'TOUR';
            case ExperienceType.TourVirtualV1:
                return 'VISITA';
            case ExperienceType.TourVirtualV2:
                return 'VISITA';
            case ExperienceType.TourVirtualV3:
                return 'VISITA';
            case ExperienceType.TourVirtualPisoPiloto:
                return 'VISITA VIRTUAL';
            case ExperienceType.TourVirtualPatio:
                return 'PATIO';
            case ExperienceType.ZonasComunes:
                return 'VISITA';
            case ExperienceType.PisoPiloto:
                return 'PISO';
            case ExperienceType.VistasReales:
                return 'VISTAS';
            case ExperienceType.SolarSimulation:
                return 'SIMULADOR';
            case ExperienceType.Configurator:
                return 'CONFIGURADOR';
            case ExperienceType.MaquetaVirtual:
                return 'MAQUETA';
            default:
                return '';
        }
    },

    GetFooterExperienceTitleSecondPart: (experience) => {
        if (experience === null) return '';

        switch (experience.type) {
            case ExperienceType.TourVirtual:
                return 'VIRTUAL';
            case ExperienceType.TourVirtualV0:
                return 'VIRTUAL';
            case ExperienceType.TourVirtualV1:
                return 'VIRTUAL 01';
            case ExperienceType.TourVirtualV2:
                return 'VIRTUAL 02';
            case ExperienceType.TourVirtualV3:
                return 'VIRTUAL 03';
            case ExperienceType.TourVirtualPisoPiloto:
                return 'PISO PILOTO';
            case ExperienceType.ZonasComunes:
                return 'ZONAS COMUNES';
            case ExperienceType.TourVirtualPatio:
                return 'PATIO';
            case ExperienceType.PisoPiloto:
                return 'PILOTO';
            case ExperienceType.VistasReales:
                return 'REALES';
            case ExperienceType.SolarSimulation:
                return '';
            case ExperienceType.Configurator:
                return '';
            case ExperienceType.MaquetaVirtual:
                return 'VIRTUAL';
            default:
                return '';
        }
    }
};

