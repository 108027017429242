import React from 'react';
import PropTypes from 'prop-types';

const VideoMiniPlayer = ({ url }) => (
  <div className="video-player-mini">
    <video
      className="video"
      autoPlay
      controls={false}
      disablePictureInPicture
      controlsList="nofullscreen"
      muted
      loop
      playsInline
      src={`${url}`}
    />
  </div>
    );

VideoMiniPlayer.propTypes = {
    url: PropTypes.string.isRequired,
};

export default VideoMiniPlayer;
