import React from 'react';
import PropTypes from 'prop-types';

const MainWrapper = ({ children }) => (
  <div className="wrapper">
    {children}
  </div>
);

MainWrapper.propTypes = {
  children: PropTypes.element.isRequired,
};

export default MainWrapper;
