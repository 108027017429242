import React from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import PropTypes from 'prop-types';
import FooterCreditsFullScreen from './Component/FooterCreditsFullScreen';
import {
    AerialIcon,
    BackFromExperienceArrow, CloseButton,
    ConfiguratorIcon,
    MultimediaIcon,
    SolarSimulationIcon,
    TourIcon,
    TourVirtualIcon, VideoPlayIcon,
    VistasIcon, VistasReales, VistasRealesCulmia,
} from '../../shared/svg/components';
import { ExperienceType } from '../../model/constants';


const HeaderExperience = ({ isMaximized, onMaximize, onMinimize }) => {
    const closeExperience = useStoreActions(actions => actions.closeExperience);

    const closeHandler = () => {
        closeExperience();
    };


    return (
      <div className="header-experience">
        {/* <FooterCreditsFullScreen isMaximized={isMaximized} onMaximize={onMaximize} onMinimize={onMinimize} /> */}
        <button type="button" className="back-button" onClick={closeHandler}>
          <CloseButton className="icon" />
        </button>
      </div>
    );
};

HeaderExperience.propTypes = {
    isMaximized: PropTypes.bool.isRequired,
    onMaximize: PropTypes.func.isRequired,
    onMinimize: PropTypes.func.isRequired,
};

export default HeaderExperience;
