import React from 'react';
import PropTypes from 'prop-types';
import { useStoreActions } from 'easy-peasy';
import { ExperienceType } from '../../../model/constants';
import {
    ConfiguratorIcon,
    ConfiguratorIconShow, MaquetaVirtualIcon,
    SimuladorSolarIconShow,
    SolarSimulationIcon,
    TourVirtualIcon,
    VistasReales,
    VistasRealesWhite,
} from '../../../shared/svg/components';
import { ExperienceUtils } from '../../../utils/ExperienceUtils';

const ExperienceButton = ({ experience, isMobilePortrait }) => {
    const openExperience = useStoreActions(actions => actions.openExperience);

    const getExperienceIcon = () => {
        switch (experience.type) {
            case ExperienceType.TourVirtual:
            case ExperienceType.TourVirtualV0:
            case ExperienceType.TourVirtualV1:
            case ExperienceType.TourVirtualV2:
            case ExperienceType.TourVirtualV3:
            case ExperienceType.TourVirtualPisoPiloto:
            case ExperienceType.TourVirtualPatio:
            case ExperienceType.PisoPiloto:
            case ExperienceType.ZonasComunes:
                return <TourVirtualIcon className="icon" />;
            case ExperienceType.VistasReales:
                return <VistasRealesWhite className="icon" />;
            case ExperienceType.SolarSimulation:
                return <SimuladorSolarIconShow className="icon" />;
            case ExperienceType.Configurator:
                return <ConfiguratorIconShow className="icon" />;
            case ExperienceType.MaquetaVirtual:
                return <MaquetaVirtualIcon className="icon" />;
            default:
                return '';
        }
    };

    const getExperienceTitle = () => {

    };

    const getExperienceBackground = () => {
        const src = '';
        switch (experience.type) {
            case ExperienceType.TourVirtual:
            case ExperienceType.TourVirtualV0:
            case ExperienceType.TourVirtualV1:
            case ExperienceType.TourVirtualV2:
            case ExperienceType.TourVirtualV3:
            case ExperienceType.TourVirtualPisoPiloto:
            case ExperienceType.PisoPiloto:
            case ExperienceType.ZonasComunes:
                return `${process.env.PUBLIC_URL}/img/visita-virtual-bkg.png`;
            case ExperienceType.TourVirtualPatio:
                return `${process.env.PUBLIC_URL}/img/patio.jpg`;
            case ExperienceType.VistasReales:
                return `${process.env.PUBLIC_URL}/img/vistas-reales-bkg.png`;
            case ExperienceType.SolarSimulation:
                return `${process.env.PUBLIC_URL}/img/simulador-solar-bkg.png`;
            case ExperienceType.Configurator:
                return `${process.env.PUBLIC_URL}/img/configurator-bkg.png`;
            case ExperienceType.MaquetaVirtual:
                return `${process.env.PUBLIC_URL}/img/maqueta-virtual-bkg.png`;
            default:
                return '';
        }
    };

    return (
      <button
        className={`experience-button${isMobilePortrait ? '-portrait' : ''}`}
        type="button"
        onClick={() => openExperience(experience)}
      >
        <div className="background">
          <div className="container-image">
            <img
              src={getExperienceBackground()}
              alt={experience.type}
            />
          </div>
          <div className="dark-overlay" />
          <div className="container-content">
            <div className="container-icon">{getExperienceIcon()}</div>
            <div className="title">{ExperienceUtils.GetExperienceTitle(experience)}</div>
            <div className="divider" />
            <div className="experience-type">EXPERIENCIA</div>
          </div>
        </div>
      </button>
    );
};

ExperienceButton.propTypes = {
    experience: PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
        url: PropTypes.string,
    }).isRequired,
    isMobilePortrait: PropTypes.bool.isRequired,
};

export default ExperienceButton;
