import React, { useRef } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { isMobile } from 'react-device-detect';
import useWindowSize from '../../hooks/useWindowSize';
import { ExperienceType } from '../../model/constants';

const IframePlayer = () => {
    const currentExperience = useStoreState(state => state.currentExperience);
    const ref = useRef();
    const windowsSize = useWindowSize();
    const isPortrait = () => windowsSize.height > windowsSize.width;

    const isMobilePortrait = () => isMobile || isPortrait();

    return (
      <div className={`iframe-player${isMobilePortrait() ? '-portrait' : ''}`}>
        <iframe
          className="iframe"
          ref={ref}
          title="iframe"
          id="iframe"
          width="100%"
          height="100%"
          frameBorder={0}
          src={(currentExperience.type === ExperienceType.SolarSimulation && isMobilePortrait())
              ? currentExperience.mobileUrl : currentExperience.url}
        />
      </div>

    );
};

export default IframePlayer;
