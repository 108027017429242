import * as React from "react";
const SvgCloseButton = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="m20.844 19.805 6.785-6.785a.575.575 0 0 0 .176-.422.575.575 0 0 0-.176-.422.59.59 0 0 0-.434-.176.575.575 0 0 0-.422.176L20 18.949l-6.773-6.773a.59.59 0 0 0-.434-.176.575.575 0 0 0-.422.176.575.575 0 0 0-.176.422c0 .164.059.304.176.421l6.785 6.786-6.785 6.773a.575.575 0 0 0-.176.422c0 .164.059.305.176.422.063.062.129.11.2.14a.814.814 0 0 0 .234.036c.07 0 .14-.012.21-.035a.625.625 0 0 0 .212-.141L20 20.648l6.773 6.774c.063.062.13.11.2.14a.7.7 0 0 0 .445 0 .625.625 0 0 0 .21-.14.575.575 0 0 0 .177-.422.575.575 0 0 0-.176-.422l-6.785-6.773Z"
    />
  </svg>
);
export default SvgCloseButton;
