import React from 'react';
import PropTypes from 'prop-types';

const RenderPlayer = ({ url }) => {
    console.log();
    return (
      <div className="render-player">
        <img src={url} alt="render" />
      </div>
    );
};

RenderPlayer.propTypes = {
    url: PropTypes.string.isRequired,
};

export default RenderPlayer;
