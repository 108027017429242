import * as React from "react";
const SvgBackFromExperienceArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={38}
    height={26}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M.79 12.285 12.8.293A.959.959 0 0 1 13.505 0c.273 0 .508.098.703.293.195.195.293.43.293.703a.959.959 0 0 1-.293.703L3.914 11.992h32.578c.287 0 .528.098.723.293a.982.982 0 0 1 .293.723.959.959 0 0 1-.293.703.982.982 0 0 1-.723.293H3.914l10.293 10.293c.195.195.293.43.293.703a.959.959 0 0 1-.293.703.858.858 0 0 1-.332.235c-.117.039-.24.058-.371.058-.13 0-.26-.02-.39-.058a.898.898 0 0 1-.313-.235L.789 13.711a.959.959 0 0 1-.293-.703c0-.287.098-.527.293-.723Z"
    />
  </svg>
);
export default SvgBackFromExperienceArrow;
