export const ExperienceType = {
    TourVirtual: "tour-virtual",
    TourVirtualV0: "tour-virtual-v0",
    TourVirtualV1: "tour-virtual-v1",
    TourVirtualV2: "tour-virtual-v2",
    TourVirtualV3: "tour-virtual-v3",
    TourVirtualPisoPiloto: "tour-virtual-piso-piloto",
    TourVirtualPatio: "tour-virtual-patio",
    MaquetaVirtual: "maqueta-virtual",
    PisoPiloto: "piso-piloto",
    VistasReales: "vistas-reales",
    SolarSimulation: "solar-simulation",
    Configurator: "configurator",
    ZonasComunes: "zonas-comunes",
    // Aerial: "aerial",
    // Gallery: "gallery",
}
