import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import MainWrapper from './MainWrapper';
import DigitalShow from '../DigitalShow/DigitalShow';

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/">
          <Redirect to="/estepona" />
        </Route>
        <Route exact path="/:promotionId" component={DigitalShow} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
