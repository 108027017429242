import React from 'react';
import { useStoreState } from 'easy-peasy';
import { isMobile } from 'react-device-detect';
import useWindowSize from '../../hooks/useWindowSize';

const VideoPlayer = () => {
    const currentVideoUrl = useStoreState(state => state.currentVideoUrl);
    const windowsSize = useWindowSize();
    const isPortrait = () => windowsSize.height > windowsSize.width;

    const isMobilePortrait = () => isMobile || isPortrait();
    return (
      <div className={`video-player${isMobilePortrait() ? '-portrait' : ''}`}>
        <video
          className="video"
          autoPlay
          controls
          disablePictureInPicture
          controlsList="nodownload"
          muted
          loop
          playsInline
          src={`${currentVideoUrl}`}
        />
      </div>
    );
};

export default VideoPlayer;
