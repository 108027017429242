import React from 'react';

// project imports
import { useStoreActions, useStoreState } from 'easy-peasy';
import { CloseButton } from '../../shared/svg/components';

const LegalNote = () => {
  const settings = useStoreState(state => state.settings);
  const isLegalNoteOpened = useStoreState(state => state.isLegalNoteOpened);
  const setLegalNoteOpened = useStoreActions(actions => actions.setLegalNoteOpened);

  return (isLegalNoteOpened
    && (
      <div className={`legal-note-popup ${isLegalNoteOpened ? ' opened' : ''}`}>
        <div className={`background${isLegalNoteOpened ? ' opened' : ''}`} />
        <div className={`panel${isLegalNoteOpened ? ' opened' : ''}`}>
          <button
            type="button"
            className="close-button"
            onClick={() => setLegalNoteOpened(false)}
          >
            <CloseButton className="icon" />
          </button>
          <div className="container-content">
            <div className="header">Aviso Legal</div>
            <div className="body" dangerouslySetInnerHTML={{ __html: settings.legalNote }} />
          </div>
        </div>
      </div>
    ));
};
export default LegalNote;
