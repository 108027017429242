import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { StoreProvider, createStore } from 'easy-peasy';
import 'bootstrap/dist/css/bootstrap.css';
import '../../scss/app.scss';
import { QueryClient, QueryClientProvider } from 'react-query';
import Router from './Router';
import model from '../../model/model';

const store = createStore(model);

const App = () => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            },
        },
    });
    return (
      <StoreProvider store={store}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </QueryClientProvider>
      </StoreProvider>
    );
};

export default App;
