import * as React from "react";
const SvgMaximizeButton = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={51}
    height={45}
    fill="none"
    {...props}
  >
    <g filter="url(#maximize_button_svg__a)">
      <path
        fill="#fff"
        d="M40.094 16.797a.767.767 0 0 1-.563-.235.767.767 0 0 1-.234-.562v-3.203a.767.767 0 0 0-.234-.563A.767.767 0 0 0 38.5 12h-3.203a.767.767 0 0 1-.563-.234.767.767 0 0 1-.234-.563c0-.219.078-.406.234-.562a.767.767 0 0 1 .563-.235H38.5a2.32 2.32 0 0 1 1.703.703c.469.47.703 1.032.703 1.688V16a.767.767 0 0 1-.234.563.785.785 0 0 1-.578.234Zm-28.797 0a.767.767 0 0 1-.563-.235A.767.767 0 0 1 10.5 16v-3.203c0-.656.234-1.219.703-1.688a2.32 2.32 0 0 1 1.703-.703h3.188c.229 0 .422.078.578.235a.767.767 0 0 1 .234.562.767.767 0 0 1-.234.563.785.785 0 0 1-.578.234h-3.188a.785.785 0 0 0-.578.234.767.767 0 0 0-.234.563V16a.767.767 0 0 1-.235.563.767.767 0 0 1-.562.234Zm4.797 17.61h-3.188a2.32 2.32 0 0 1-1.703-.704A2.32 2.32 0 0 1 10.5 32v-3.203c0-.219.078-.406.234-.563a.767.767 0 0 1 .563-.234c.219 0 .406.078.562.234a.767.767 0 0 1 .235.563V32c0 .219.078.406.234.563a.785.785 0 0 0 .578.234h3.188c.229 0 .422.078.578.234a.767.767 0 0 1 .234.563c0 .229-.078.422-.234.578a.786.786 0 0 1-.578.234Zm22.406 0h-3.203a.767.767 0 0 1-.563-.235.785.785 0 0 1-.234-.578c0-.219.078-.407.234-.563a.767.767 0 0 1 .563-.234H38.5a.767.767 0 0 0 .563-.234.767.767 0 0 0 .234-.563v-3.203c0-.219.078-.406.234-.563a.767.767 0 0 1 .563-.234c.229 0 .422.078.578.234a.767.767 0 0 1 .234.563V32a2.32 2.32 0 0 1-.703 1.703 2.32 2.32 0 0 1-1.703.703Z"
      />
    </g>
    <defs>
      <filter
        id="maximize_button_svg__a"
        width={50.406}
        height={44}
        x={0.5}
        y={0.406}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2248_17236"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_2248_17236"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export default SvgMaximizeButton;
